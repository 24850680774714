import IApi from 'modules/shared/domain/IApi';
import api from 'modules/shared/infrastructure/api/Api';
import BaseRepository from 'modules/shared/infrastructure/repositories/BaseRepository';
import IProviderContactListRepository from '../../domain/ProviderContactList/IProviderContactList';
import { providerContactListDTOMapper } from '../dto/providerContactListDTOMapper';
import { API_PROVIDER_CONTACT_LIST } from './url';
import { providerContactListMapper } from '../../domain/ProviderContactList/providerContactListMapper';

class ProviderContactListRepository
  extends BaseRepository
  implements IProviderContactListRepository
{
  private _api: IApi;
  private _apiProviderContactList: string;

  constructor({ api, apiProviderContactList }) {
    super();
    this._api = api;
    this._apiProviderContactList = apiProviderContactList;
  }

  getProviderContactList({ queryParams }: { queryParams: object }) {
    const url = `${this._apiProviderContactList}${this._createQueryParams(
      queryParams
    )}`;
    return this._api
      .get(url)
      .then((res) => providerContactListDTOMapper(res))
      .then((dto) => providerContactListMapper(dto));
  }

  postNewContact({
    providerId,
    body
  }: {
    providerId: number;
    body: {
      name: string;
      telephone: string;
      email: string;
      role: string;
    };
  }) {
    const url = `${this._apiProviderContactList}/${providerId}/contacts`;
    return this._api.post(url, body).then((res) => res);
  }

  deleteContact(providerId: number, contactUid: string) {
    const url = `${this._apiProviderContactList}/${providerId}/contacts/${contactUid}`;
    return this._api.delete(url).then((res) => res);
  }

  putContact({
    providerId,
    contactUid,
    body
  }: {
    providerId: number;
    contactUid: string;
    body: { role: string; name: string; telephone: string; email: string };
  }) {
    const url = `${this._apiProviderContactList}/${providerId}/contacts/${contactUid}`;
    return this._api.put(url, body).then((res) => res);
  }
}

export default new ProviderContactListRepository({
  api: api,
  apiProviderContactList: API_PROVIDER_CONTACT_LIST
});
export { ProviderContactListRepository };
