export enum UserRole {
  INBOUND_PROVIDER = 'INBOUND_PROVIDER',
  INBOUND_PROVIDER_NOT_JIT = 'INBOUND_PROVIDER_NOT_JIT',
  INBOUND_PROVIDER_JIT = 'INBOUND_PROVIDER_JIT',
  INBOUND_PROVIDER_DD = 'INBOUND_PROVIDER_DD',
  INBOUND_SEAT = 'INBOUND_SEAT',
  OUTBOUND = 'OUTBOUND',
  NONE = 'NONE',
  INBOUND_SUPER_ADMIN = 'INBOUND_SUPER_ADMIN'
}

export enum UserVisibility {
  OUTBOUND = 'OUTBOUND',
  INBOUND_INHOUSE = 'INBOUND_INHOUSE'
}

export const UserVisibilityText = {
  OUTBOUND: 'OUTBOUND',
  INBOUND_INHOUSE: 'INBOUND-INHOUSE'
};

export const USER_VISIBILITY_LIST = [
  {
    key: UserVisibility.INBOUND_INHOUSE,
    text: UserVisibilityText.INBOUND_INHOUSE
  },
  {
    key: UserVisibility.OUTBOUND,
    text: UserVisibilityText.OUTBOUND
  }
];

export enum UserDashboards {
  LANDING = 14,
  NOJIT = 1,
  ENDOFPRODUCTION = 2,
  JIT = 3,
  FAMILYCOVERAGE = 4,
  DD = 5,
  FACTORYBCN = 6,
  SUPPLIES = 7,
  REFILLS = 8,
  TRANSFERS = 9,
  ENTRIES = 10,
  MAESTROS = 12,
  USERS = 13,
  EXPIRATION = 15,
  STOCKS = 16,
  STUCKLISTE_SUPPLIERS = 17
}

export const UserRoleText = {
  PROVIDER: 'Provider',
  SEAT: 'SEAT',
  SUPER_ADMIN: 'Super Admin'
};

export const ROLES_SELECT_LIST = [
  { key: UserRole.INBOUND_PROVIDER, text: UserRoleText.PROVIDER },
  { key: UserRole.INBOUND_SEAT, text: UserRoleText.SEAT },
  { key: UserRole.INBOUND_SUPER_ADMIN, text: UserRoleText.SUPER_ADMIN }
];

export enum UserGroup {
  INBOUND = 'INBOUND',
  OUTBOUND = 'OUTBOUND',
  NONE = 'NONE'
}

const internalRoles = [
  UserRole.INBOUND_SUPER_ADMIN,
  UserRole.INBOUND_SEAT
] as const;

export { internalRoles };
