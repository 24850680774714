import { IUserProvidersDTO } from '../infrastructure/dto/UserProvidersDTO';

export interface IUserProviders {
  id: number;
  name: string;
}
interface GenerateFromDTOParams {
  providers: IUserProvidersDTO[];
}
class UserProvidersData {
  providers: IUserProviders[];

  constructor({ providers }: UserProvidersData) {
    this.providers = providers;
  }

  static generateFromDTO({
    providers
  }: GenerateFromDTOParams): UserProvidersData {
    let formatProviders: IUserProviders[] = [];
    if (providers.length) {
      providers.forEach((providerItem) => {
        formatProviders.push({
          id: providerItem.id,
          name: providerItem.name
        });
      });
    }
    return new UserProvidersData({
      providers: formatProviders
    });
  }
}

export default UserProvidersData;
