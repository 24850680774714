import IUsersRepository from '../domain/IUsersRepository';
import UsersData from '../domain/Users';
import usersRepository from '../infrastructure/repositories/UsersRepository';

class GetUsersDataService {
  private _usersRepository: IUsersRepository;

  constructor({ repository }) {
    this._usersRepository = repository;
  }

  execute({ queryParams }: { queryParams: object }) {
    return this._usersRepository
      .get({ queryParams })
      .then((res) => UsersData.generateFromDTO(res));
  }
}

export default new GetUsersDataService({
  repository: usersRepository
});

export { GetUsersDataService };
