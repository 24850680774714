import { ColumnProps } from 'modules/inbound/shared/domain/ColumnsTypes';
import { IUserDashboards } from 'modules/inbound/users/domain/UserDashboardsData';
import { IUserProviders } from 'modules/inbound/users/domain/UserProvidersData';
import { User } from 'modules/inbound/users/domain/Users';
import { ColumnSelected } from 'modules/shared/layout/components/TableHeader/types';
import { SelectItemProps } from 'modules/shared/layout/elements/Selects/typing';

export enum ProviderDashboards {
  NOJIT = 'NOJIT',
  JIT = 'JIT',
  DD = 'DD'
}
export interface SelectedUser {
  id?: string;
  email: string;
  groups: SelectItemProps[];
  username: string;
  role: SelectItemProps;
  providers: SelectItemProps[];
  dashboards: SelectItemProps[];
}

export interface UsersDataState {
  data: {
    users: User[];
    providers: IUserProviders[];
    dashboards: IUserDashboards[];
  };
  columns: ColumnProps[];
  pagination: {
    page: number;
    size: number;
    pageCount: number;
    totalCount: number;
  };
  requestStatus: string;
  createUserStatus: string;
  response: {};
  userActionResponse: {};
  userActionRequest: string;
  usersFile: any;
  downloadRequestStatus: string;
  selectedUser: SelectedUser | null;
  updateResponse: {};
  updateUserStatus: string;
  providersRequestStatus: string;
  dashboardsRequestStatus: string;
  search: string;
  selectedColumn: ColumnSelected;
  isOpenUserForm: boolean;
  isEditUserForm: boolean;
}
