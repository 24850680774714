import IUsersRepository from '../domain/IUsersRepository';
import UserProvidersData from '../domain/UserProvidersData';
import usersRepository from '../infrastructure/repositories/UsersRepository';

class GetUserProvidersDataService {
  private _usersRepository: IUsersRepository;

  constructor({ repository }) {
    this._usersRepository = repository;
  }

  execute() {
    return this._usersRepository
      .getUserProviders()
      .then((res) => UserProvidersData.generateFromDTO(res));
  }
}

export default new GetUserProvidersDataService({
  repository: usersRepository
});

export { GetUserProvidersDataService };
