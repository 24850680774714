import {
  API_USERS,
  API_GET_USER_PROVIDERS,
  API_RESET_PASSWORD,
  API_GET_USER_DASHBOARDS,
  API_GET_DOWNLOAD_USERS
} from './urls';
import IApi from 'modules/shared/domain/IApi';
import api from 'modules/shared/infrastructure/api/Api';
import UsersDTO from '../dto/UsersDTO';
import IUsersRepository from '../../domain/IUsersRepository';
import BaseRepository from 'modules/shared/infrastructure/repositories/BaseRepository';
import UserProvidersDTO from '../dto/UserProvidersDTO';
import UserDashboardsDTO from '../dto/UserDashboardsDTO';

class UsersRepository extends BaseRepository implements IUsersRepository {
  private _api: IApi;
  private _apiUsers: string;
  private _apiGetUserProviders: string;
  private _apiResetPassword: string;
  private _apiGetUserDashboards: string;
  private _apiGetDownloadUsers: string;

  constructor({
    api,
    apiUsers,
    apiGetUserProviders,
    apiResetPassword,
    apiGetUserDashboards,
    apiGetDownloadUsers
  }) {
    super();
    this._api = api;
    this._apiUsers = apiUsers;
    this._apiGetUserProviders = apiGetUserProviders;
    this._apiResetPassword = apiResetPassword;
    this._apiGetUserDashboards = apiGetUserDashboards;
    this._apiGetDownloadUsers = apiGetDownloadUsers;
  }
  get({ queryParams }: { queryParams: object }) {
    const url = `${this._apiUsers}${this._createQueryParams(queryParams)}`;
    return this._api.get(url).then((res) => new UsersDTO(res));
  }

  getUserProviders() {
    const url = this._apiGetUserProviders;
    return this._api.get(url).then((res) => new UserProvidersDTO(res));
  }

  post(body) {
    const url = this._apiUsers;
    return this._api.post(url, body).then((res) => res);
  }

  updateUser(body) {
    const url = this._apiUsers;
    return this._api.put(url, body).then((res) => res);
  }

  resetPassword(userId) {
    const url = this._apiResetPassword;
    return this._api.put(url + `/${userId}`).then((res) => res);
  }
  deleteUser(userId) {
    const url = this._apiUsers;
    return this._api.delete(url + `/${userId}`).then((res) => res);
  }

  getUserDashboards() {
    const url = this._apiGetUserDashboards;
    return this._api.get(url).then((res) => new UserDashboardsDTO(res));
  }

  downloadUsers({ queryParams }) {
    const url = `${this._apiGetDownloadUsers}${this._createQueryParams(
      queryParams
    )}`;
    const headers = {
      Accept:
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    };
    return this._api.getBlob({ url, headers });
  }
}

export default new UsersRepository({
  api: api,
  apiUsers: API_USERS,
  apiGetUserProviders: API_GET_USER_PROVIDERS,
  apiResetPassword: API_RESET_PASSWORD,
  apiGetUserDashboards: API_GET_USER_DASHBOARDS,
  apiGetDownloadUsers: API_GET_DOWNLOAD_USERS
});

export { UsersRepository };
