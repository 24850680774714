import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ExtensibleMenuProps } from './typing';
import { RouteItemProps, RouteSubItemProps } from 'infrastructure/routes/types';
import {
  MenuUnorderedList,
  WrapperItem,
  Item,
  ArrowDirection,
  SubItems,
  SubItem,
  Wrapper,
  SubItemsWrapper
} from './styles';
import { ReactComponent as ArrowIcon } from 'assets/icons/arrowIcon.svg';
import {
  getActiveMenuButton,
  getActiveSubItemMenuButton,
  getIsMenuOpened,
  setActiveMenuButton,
  setActiveSubItemMenuButton,
  setIsMenuOpened
} from 'modules/shared/infrastructure/redux/SharedReducer';
import { HeaderTranslations } from 'modules/shared/infrastructure/i18n/Header/typing';
import { HeaderTexts } from 'modules/shared/infrastructure/i18n/Header/index';
import localeService from 'infrastructure/i18n/LocaleService';
import useBreakpoints from 'modules/shared/hooks/useBreakpoints';
import { getUniqueId } from 'modules/shared/services/getUniqueId';

const translations: HeaderTranslations = {
  ...HeaderTexts[localeService.getLanguage()]
};

const ExtensibleMenu = ({
  items = [],
  outboundUserRole
}: ExtensibleMenuProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { pathname } = useLocation();
  const { isTablet } = useBreakpoints();

  const isOpened = useSelector(getIsMenuOpened);
  const activeMenuButton = useSelector(getActiveMenuButton);
  const activeSubItemMenuButton = useSelector(getActiveSubItemMenuButton);

  const handleItemClick = (item: RouteItemProps) => () => {
    if (!isOpened) {
      !isTablet && dispatch(setIsMenuOpened(true));
      if (activeMenuButton === item.name) return;
    }
    if (item.path) {
      navigate(item.path);
      dispatch(setIsMenuOpened(false));
    }
    dispatch(setActiveMenuButton(item.name));
  };

  const handleSubItemClick =
    (subitem: RouteSubItemProps, isSecondLevel?: boolean) => () => {
      if (subitem.path) {
        navigate(subitem.path);
        dispatch(setIsMenuOpened(false));
      }
      if (isSecondLevel) {
        dispatch(
          setActiveSubItemMenuButton(
            `${activeSubItemMenuButton.split('-')[0]}-${subitem.name}`
          )
        );
      } else {
        dispatch(setActiveSubItemMenuButton(subitem.name));
      }
    };

  const checkThreeLayers = (item) =>
    item.subItems?.some((subitem) => subitem.path === pathname) ||
    item.subItems?.some((subitem) =>
      subitem.subItems?.some((subsubitem) => subsubitem.path === pathname)
    );

  const checkTwoLayers = (subitem) =>
    pathname === subitem.path ||
    subitem.subItems?.some((subsubitem) => subsubitem.path === pathname);

  const checkOneLayer = (subsubitem) => pathname === subsubitem.path;

  const getArrowDirection = (subitem) => {
    if (subitem.path) {
      return ArrowDirection.FINAL;
    }
    if (
      activeSubItemMenuButton.includes(subitem.name) ||
      checkTwoLayers(subitem)
    ) {
      return ArrowDirection.OPEN;
    }
    return ArrowDirection.CLOSE;
  };

  return (
    <MenuUnorderedList data-testid="menu-list" isOpened={isOpened}>
      {items.map((item) => (
        <Wrapper key={getUniqueId()}>
          <WrapperItem
            className="menu-button"
            onClick={handleItemClick(item)}
            isOpened={isOpened}
            active={activeMenuButton === item.name || checkThreeLayers(item)}
          >
            <item.icon className="icon" />
            {isOpened && (
              <Item
                arrowDirection={
                  activeMenuButton === item.name || checkThreeLayers(item)
                    ? ArrowDirection.OPEN
                    : ArrowDirection.CLOSE
                }
                data-testid={item.name}
              >
                <span>{translations[item.name]}</span>
                {item.subItems?.length && <ArrowIcon />}
              </Item>
            )}
          </WrapperItem>
          {isOpened &&
            ((activeMenuButton === item.name && item.subItems?.length) ||
              (outboundUserRole && checkThreeLayers(item))) && (
              <SubItemsWrapper secondLayer={false}>
                {item.subItems?.map((subitem) => (
                  <SubItems key={getUniqueId()}>
                    <SubItem
                      secondLayer={false}
                      arrowDirection={getArrowDirection(subitem)}
                      onClick={handleSubItemClick(subitem)}
                      active={checkTwoLayers(subitem)}
                      data-testid={subitem.name}
                    >
                      <span>{translations[subitem.name]}</span>
                      <ArrowIcon />
                    </SubItem>
                    {subitem.subItems &&
                      (activeSubItemMenuButton.includes(subitem.name) ||
                        checkTwoLayers(subitem)) && (
                        <SubItemsWrapper secondLayer={true}>
                          {subitem.subItems.map((subsubitem) => (
                            <SubItem
                              secondLayer={true}
                              key={getUniqueId()}
                              arrowDirection={ArrowDirection.FINAL}
                              onClick={handleSubItemClick(subsubitem, true)}
                              active={checkOneLayer(subsubitem)}
                              data-testid={subsubitem.name}
                            >
                              <span>{translations[subsubitem.name]}</span>
                              <ArrowIcon />
                            </SubItem>
                          ))}
                        </SubItemsWrapper>
                      )}
                  </SubItems>
                ))}
              </SubItemsWrapper>
            )}
        </Wrapper>
      ))}
    </MenuUnorderedList>
  );
};

export default ExtensibleMenu;
