export interface IUserProvidersDTO {
  id: number;
  name: string;
}

class UserProvidersDTO {
  providers: IUserProvidersDTO[];

  constructor({ providers }) {
    this.providers = providers;
  }
}
export default UserProvidersDTO;
