import { LocationStatus } from '../../domain/GeolocatedTransport';

interface GeolocatedVesselsDTO {
  imo: string;
  name: string;
  lastLocationCoordinates: { longitude: number; latitude: number };
  origin: string;
  originCountry: string;
  originPortExitDate: string;
  destination: string;
  destinationCountry: string;
  eta: string;
  updateDate: string;
  status: LocationStatus;
  vins: string[];
}

class GeolocatedVesselsResponseDTO {
  vessels: GeolocatedVesselsDTO[];

  constructor({ vessels }) {
    this.vessels = vessels;
  }
}

export default GeolocatedVesselsResponseDTO;
