import { BreadCrumbs, RouteItemProps } from './types';
import { OUTBOUND_ROUTES } from './outboundRoutes';
import { INBOUND_ROUTES } from './inboundRoutes';
import { UserGroup, UserRole } from 'modules/shared/domain/UserRole';
import { LANDING_ROUTES } from './landingRoutes';

class RoutingService {
  static readonly instance: RoutingService;
  private _landingRoutes: RouteItemProps[] = [];
  private _inboundRoutes: RouteItemProps[] = [];
  private _outboundRoutes: RouteItemProps[] = [];

  constructor({ landingRoutes, inboundRoutes, outboundRoutes }) {
    this._landingRoutes = landingRoutes;
    this._inboundRoutes = inboundRoutes;
    this._outboundRoutes = outboundRoutes;
  }

  getLandingRoutes(userGroup: UserGroup[]) {
    return this._landingRoutes.map((landingRoute) => {
      return {
        ...landingRoute,
        subItems: landingRoute.subItems?.filter(
          (subItem) =>
            (userGroup.includes(UserGroup.INBOUND) &&
              subItem.name === 'inboundInhouse') ||
            (userGroup.includes(UserGroup.OUTBOUND) &&
              subItem.name === 'outbound')
        )
      };
    });
  }

  getOutboundRoutes() {
    return this._outboundRoutes;
  }

  // TO-DO: Remove role parameter once panel 17 appears in user information from BE
  getInboundRoutes(permissions: number[], role?: UserRole) {
    const permittedRoutes = this._inboundRoutes
      .map((route) => {
        return {
          ...route,
          subItems: route.subItems?.filter(
            (subItem) =>
              permissions.includes(subItem.id!) ||
              (role === UserRole.INBOUND_SUPER_ADMIN && subItem.id === 17)
          )
        };
      })
      .filter(
        (route) => route?.subItems?.length ?? permissions.includes(route.id!)
      );

    return permittedRoutes;
  }

  // TO-DO: Remove role once panel 17 appears in user information from BE
  getInboundSidebarRoutes(
    permissions: number[],
    role?: UserRole
  ): RouteItemProps[] {
    return this.getInboundRoutes(permissions, role).filter(
      (route) => route.name !== 'management'
    );
  }

  getInboundManagementSidebarRoutes(permissions: number[]): RouteItemProps[] {
    const managementRoutes = this.getInboundRoutes(permissions).filter(
      (route) => route.name === 'management'
    );
    const visibleManagementSidebarRoutes =
      this.getManagementVisibleSidebarRoutes(managementRoutes);
    if (visibleManagementSidebarRoutes) {
      managementRoutes[0].subItems = visibleManagementSidebarRoutes;
    }
    return managementRoutes;
  }

  getManagementVisibleSidebarRoutes(managementRoutes) {
    return managementRoutes[0]?.subItems.filter(
      (subSubItem) =>
        subSubItem.name !== 'warehouseCapacity' &&
        subSubItem.name !== 'lineGroups'
    );
  }

  getOutboundBreadCrumbs(pathname: string): BreadCrumbs {
    let resultingBreadcrumbs: BreadCrumbs = [];
    const allRoutes = this._outboundRoutes.concat(this._landingRoutes);

    allRoutes.forEach((process) =>
      process.subItems?.forEach((category) => {
        if (category.subItems?.length) {
          category.subItems.forEach((pannel) => {
            if (pannel.path === pathname) {
              resultingBreadcrumbs = [
                { name: process.name },
                { name: category.name },
                { name: pannel.name }
              ];
            }
          });
        }
        if (category.path === pathname) {
          resultingBreadcrumbs = [
            { name: process.name },
            { name: category.name }
          ];
        }
      })
    );
    return resultingBreadcrumbs;
  }

  getInboundBreadCrumbs(pathname: string): BreadCrumbs {
    let resultingBreadcrumbs: BreadCrumbs = [];

    this._inboundRoutes.forEach((process) => {
      if (process.element) {
        if (process.path === pathname) {
          resultingBreadcrumbs = [{ name: process.name }];
        }
      }
      process.subItems?.forEach((category) => {
        if (category.subItems?.length) {
          category.subItems.forEach((pannel) => {
            if (pannel.path === pathname) {
              resultingBreadcrumbs = [
                { name: process.name },
                { name: category.name },
                { name: pannel.name }
              ];
            }
          });
        }
        if (category.path === pathname) {
          resultingBreadcrumbs = [
            { name: process.name },
            { name: category.name }
          ];
        }
      });
    });
    return resultingBreadcrumbs;
  }
}

export default new RoutingService({
  landingRoutes: LANDING_ROUTES,
  inboundRoutes: INBOUND_ROUTES,
  outboundRoutes: OUTBOUND_ROUTES
});

export { RoutingService };
