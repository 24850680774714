import IUsersRepository from '../domain/IUsersRepository';
import usersRepository from '../infrastructure/repositories/UsersRepository';

class DownloadUsersDataService {
  private _usersRepository: IUsersRepository;

  constructor({ repository }) {
    this._usersRepository = repository;
  }

  execute({ queryParams }: { queryParams: object }) {
    return this._usersRepository.downloadUsers({
      queryParams
    });
  }
}

export default new DownloadUsersDataService({
  repository: usersRepository
});

export { DownloadUsersDataService };
