import LocaleService from 'infrastructure/i18n/LocaleService';
import { InboundText } from 'modules/inbound/shared/infraestructure/i18n/inboundText';
import { InboundTranslations } from 'modules/inbound/shared/infraestructure/i18n/typing';
import { NameValues } from 'modules/inbound/shared/domain/NameValues';
import { NoJitText } from '../infrastructure/i18n/NoJitText';
import { NoJitTranslations } from '../infrastructure/i18n/typing';
import { ColumnProps } from 'modules/inbound/shared/domain/ColumnsTypes';

const noJitTranslations: NoJitTranslations & InboundTranslations = {
  ...InboundText[LocaleService.getLanguage()],
  ...NoJitText[LocaleService.getLanguage()]
};

export enum ColumnKeys {
  code = 'code',
  providerCodes = 'providerCodes',
  name = 'name',
  lines = 'lines',
  models = 'models',
  stock = 'stock',
  tierStock = 'tierStock',
  tierStockUpdatedAt = 'tierStockUpdatedAt',
  comment = 'comment',
  factoryCoverage = 'factoryCoverage',
  transitsCoverage = 'transitsCoverage',
  orderCoverage = 'orderCoverage',
  endProductionInDays = 'endProductionInDays',
  pastLoads = 'pastLoads',
  lineMovementType = 'lineMovementType',
  lineMovementAmount = 'lineMovementAmount',
  lineMovementDate = 'lineMovementDate',
  inbounders = 'inbounders',
  providerNames = 'providerNames',
  providerIds = 'providerIds',
  countries = 'countries',
  warehouses = 'warehouses'
}

export const NOJIT_COLUMNS: ColumnProps[] = [
  {
    key: NameValues.CODE,
    value: noJitTranslations.code,
    sortable: true,
    isVisibleCheck: true,
    headerVisible: false,
    columns: [
      {
        uiKey: ColumnKeys.code,
        key: NameValues.CODE,
        value: noJitTranslations.code,
        sortable: true
      }
    ]
  },
  {
    key: NameValues.PROVIDER_CODE,
    value: noJitTranslations.providerCode,
    headerVisible: false,
    headerSpan: 1,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: ColumnKeys.providerCodes,
        key: NameValues.PROVIDER_CODE,
        value: noJitTranslations.providerCode,
        sortable: false,
        tooltipInfo: noJitTranslations.providerPartTooltipInfo
      }
    ]
  },
  {
    key: NameValues.NAME,
    value: noJitTranslations.name,
    sortable: true,
    isVisibleCheck: true,
    headerVisible: false,
    columns: [
      {
        uiKey: ColumnKeys.name,
        key: NameValues.NAME,
        value: noJitTranslations.name,
        sortable: true,
        resize: true
      }
    ]
  },
  {
    key: NameValues.LINES,
    value: noJitTranslations.lines,
    sortable: true,
    isVisibleCheck: true,
    headerVisible: false,
    columns: [
      {
        uiKey: ColumnKeys.lines,
        key: NameValues.LINES,
        value: noJitTranslations.lines,
        sortable: true,
        resize: true
      }
    ]
  },
  {
    key: NameValues.MODELS,
    value: noJitTranslations.models,
    sortable: true,
    isVisibleCheck: true,
    headerVisible: false,
    columns: [
      {
        uiKey: ColumnKeys.models,
        key: NameValues.MODELS,
        value: noJitTranslations.models,
        sortable: true,
        tooltipInfo: noJitTranslations.modelCarRelation,
        resize: true
      }
    ]
  },
  {
    key: NameValues.STOCK,
    value: noJitTranslations.stock,
    sortable: true,
    isVisibleCheck: true,
    headerVisible: false,
    columns: [
      {
        uiKey: ColumnKeys.stock,
        key: NameValues.STOCK,
        value: noJitTranslations.stock,
        sortable: true,
        resize: true,
        align: 'right'
      }
    ]
  },
  {
    key: NameValues.TIER_STOCK,
    value: noJitTranslations.tierStock,
    sortable: true,
    isVisibleCheck: true,
    headerVisible: false,
    columns: [
      {
        uiKey: ColumnKeys.tierStock,
        key: NameValues.TIER_STOCK,
        value: noJitTranslations.tierStock,
        sortable: true,
        resize: true,
        align: 'right',
        tooltipInfo: noJitTranslations.stockReportedBySupplier
      }
    ]
  },
  {
    key: NameValues.TIER_STOCK_UPDATE_AT,
    value: noJitTranslations.tierStockUpdatedAt,
    sortable: true,
    isVisibleCheck: true,
    headerVisible: false,
    columns: [
      {
        uiKey: ColumnKeys.tierStockUpdatedAt,
        key: NameValues.TIER_STOCK_UPDATE_AT,
        value: noJitTranslations.tierStockUpdatedAt,
        sortable: false,
        resize: true,
        tooltipInfo: noJitTranslations.tierStockUpdatedAtTooltip
      }
    ]
  },
  {
    key: NameValues.COMMENT,
    value: noJitTranslations.comments,
    sortable: false,
    isVisibleCheck: true,
    headerVisible: false,
    columns: [
      {
        uiKey: ColumnKeys.comment,
        key: NameValues.COMMENT,
        value: noJitTranslations.comments,
        sortable: false,
        resize: true,
        tooltipInfo: noJitTranslations.commentsTooltip
      }
    ]
  },
  {
    key: NameValues.COVERAGE_WITH,
    value: noJitTranslations.coverageWithLabel,
    headerVisible: true,
    headerSpan: 3,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: ColumnKeys.factoryCoverage,
        key: NameValues.FACTORY_COVERAGE_END_DATETIME,
        value: noJitTranslations.factoryCoverage,
        sortable: true,
        tooltipInfo: noJitTranslations.factoryCoverageTooltip
      },
      {
        uiKey: ColumnKeys.transitsCoverage,
        key: NameValues.TRANSIT_COVERAGE,
        value: noJitTranslations.transitsCoverage,
        sortable: true,
        tooltipInfo: noJitTranslations.transitsCoverageTooltip
      },
      {
        uiKey: ColumnKeys.orderCoverage,
        key: NameValues.MARGIN_END_DATETIME,
        value: noJitTranslations.orders,
        sortable: false,
        tooltipInfo: noJitTranslations.coverageOrdersTooltip
      }
    ]
  },
  {
    key: NameValues.END_PRODUCTION_DATE,
    value: noJitTranslations.endProductionInDays,
    sortable: true,
    isVisibleCheck: true,
    headerVisible: false,
    columns: [
      {
        uiKey: ColumnKeys.endProductionInDays,
        key: NameValues.END_PRODUCTION_DATE,
        value: noJitTranslations.endProductionInDays,
        sortable: true,
        resize: true,
        align: 'right'
      }
    ]
  },
  {
    key: NameValues.PAST_LOADS,
    value: noJitTranslations.pastLoads,
    sortable: false,
    isVisibleCheck: true,
    headerVisible: false,
    columns: [
      {
        uiKey: ColumnKeys.pastLoads,
        key: NameValues.PAST_LOADS,
        value: noJitTranslations.pastLoads,
        sortable: false,
        tooltipInfo: noJitTranslations.pastLoadsTooltip,
        resize: true
      }
    ]
  },
  {
    key: NameValues.MOVEMENTS_LINE,
    value: noJitTranslations.movementLabel,
    headerVisible: true,
    headerSpan: 3,
    isVisibleCheck: true,
    columns: [
      {
        uiKey: ColumnKeys.lineMovementType,
        key: NameValues.MOVEMENT_TYPE,
        value: noJitTranslations.movementType,
        sortable: false,
        tooltipInfo: noJitTranslations.movementsLineTooltip,
        resize: true
      },
      {
        uiKey: ColumnKeys.lineMovementAmount,
        key: NameValues.MOVEMENT_AMOUNT,
        value: noJitTranslations.movementAmount,
        sortable: false,
        resize: true,
        align: 'right'
      },
      {
        uiKey: ColumnKeys.lineMovementDate,
        key: NameValues.MOVEMENT_DATE,
        value: noJitTranslations.movementDate,
        sortable: false,
        resize: true
      }
    ]
  },
  {
    key: NameValues.WAREHOUSES,
    value: noJitTranslations.warehouse,
    sortable: true,
    isVisibleCheck: true,
    headerVisible: false,
    columns: [
      {
        uiKey: ColumnKeys.warehouses,
        key: NameValues.WAREHOUSES,
        value: noJitTranslations.warehouse,
        sortable: true,
        resize: true
      }
    ]
  },
  {
    key: NameValues.INBOUNDER,
    value: noJitTranslations.inbounder,
    sortable: true,
    isVisibleCheck: true,
    headerVisible: false,
    columns: [
      {
        uiKey: ColumnKeys.inbounders,
        key: NameValues.INBOUNDER,
        value: noJitTranslations.inbounder,
        sortable: true,
        resize: true
      }
    ]
  },
  {
    key: NameValues.PROVIDER_NAME,
    value: noJitTranslations.providerName,
    sortable: true,
    isVisibleCheck: true,
    headerVisible: false,
    columns: [
      {
        uiKey: ColumnKeys.providerNames,
        key: NameValues.PROVIDER_NAME,
        value: noJitTranslations.providerName,
        sortable: true,
        resize: true
      }
    ]
  },
  {
    key: NameValues.PROVIDER_ID,
    value: noJitTranslations.providerId,
    sortable: true,
    isVisibleCheck: true,
    headerVisible: false,
    columns: [
      {
        uiKey: ColumnKeys.providerIds,
        key: NameValues.PROVIDER_ID,
        value: noJitTranslations.providerId,
        sortable: true,
        resize: true
      }
    ]
  },
  {
    key: NameValues.COUNTRY,
    value: noJitTranslations.country,
    sortable: true,
    isVisibleCheck: true,
    headerVisible: false,
    columns: [
      {
        uiKey: ColumnKeys.countries,
        key: NameValues.COUNTRY,
        value: noJitTranslations.country,
        sortable: true,
        resize: true
      }
    ]
  }
];
