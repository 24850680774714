import React from 'react';

export enum InboundRoutes {
  HOME = '/',
  LANDING = '/inbound/landing',
  NO_JIT = '/inbound/nojit',
  JIT_FAMILY_COVERAGE = '/inbound/jit/jit-family-coverage',
  JIT_PART_COVERAGE = '/inbound/jit/parts-coverage',
  JIT_FAMILIES = '/inbound/jit/families',
  DIRECT_DELIVERY = '/inbound/direct-delivery',
  FACTORY_BCN = '/inbound/factory-bcn',
  SUPPLIES = '/inbound/supplies',
  MATERIAL_IN_TRANSFER = '/inbound/material-in-transfer',
  TRANSFER_NEXT_HOURS = '/inbound/transfers-next-hours',
  REFILLS = '/inbound/refills',
  END_OF_PRODUCTION = '/inbound/endofproduction',
  ENTRIES_NEXT_HOURS = '/inbound/entries-next-hours',
  ENTRIES_UNLOADING_POINTS = '/inbound/entries-unloading-points',
  ENTRIES_IN_PLANT = '/inbound/entries-in-plant',
  MASTER_CALENDAR = '/inbound/master-calendar',
  MASTER_WAREHOUSE_CAPACITY = '/inbound/master-warehouse-capacity',
  MASTER_CODE_WORKSHOP = '/inbound/code-workshop-factorybcn',
  MASTER_FACTORY_BCN = '/inbound/master-factorybcn',
  MASTER_LINE_GROUPS = '/inbound/master-line-groups',
  MASTER_JIT = '/inbound/maestrosJit',
  MASTER_BODYSHOP_DD = '/inbound/master-bodyshop-dd',
  MASTER_BODYSHOP_CALENDAR = '/inbound/master-bodyshop-calendar',
  USERS = '/inbound/users',
  EXPIRATION = '/inbound/expirations',
  NEW_MASTER_WAREHOUSE_CAPACITY = '/inbound/new-master-warehouse-capacity',
  STOCKS = '/inbound/stocks',
  MASTERS_PANEL = '/inbound/masters',
  STUCKLISTE_SUPPLIERS = '/inbound/stuckliste-suppliers'
}

export interface RouteItemProps {
  name: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  id?: number;
  path?: string;
  element?: React.LazyExoticComponent<React.ComponentType<any>>;
  subItems?: RouteSubItemProps[];
  active?: boolean;
}

export interface RouteSubItemProps {
  name: string;
  id?: number;
  path?: string;
  subItems?: RouteSubItemProps[];
  active?: boolean;
  element?: React.LazyExoticComponent<React.ComponentType<any>>;
}

export type BreadCrumbs = BreadCrumbItem[];

interface BreadCrumbItem {
  name: string;
}
