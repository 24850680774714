import { LocationCoordinates, LocationStatus } from './GeolocatedTransport';

class GeolocatedVessel {
  imo: string;
  name: string;
  lastLocationCoordinates: LocationCoordinates;
  origin: string;
  originCountry: string;
  originPortExitDate: string;
  destination: string;
  destinationCountry: string;
  eta: string;
  updateDate: string;
  status: LocationStatus;
  vins: string[];

  constructor({
    imo,
    name,
    lastLocationCoordinates,
    origin,
    originCountry,
    originPortExitDate,
    destination,
    destinationCountry,
    eta,
    updateDate,
    status,
    vins
  }) {
    this.imo = imo;
    this.name = name;
    this.lastLocationCoordinates = lastLocationCoordinates;
    this.origin = origin;
    this.originCountry = originCountry;
    this.originPortExitDate = originPortExitDate;
    this.destination = destination;
    this.destinationCountry = destinationCountry;
    this.eta = eta;
    this.updateDate = updateDate;
    this.status = status;
    this.vins = vins;
  }

  static generateFromDTO({
    imo,
    name,
    lastLocationCoordinates,
    origin,
    originCountry,
    originPortExitDate,
    destination,
    destinationCountry,
    eta,
    updateDate,
    status,
    vins
  }) {
    return new GeolocatedVessel({
      imo,
      name,
      lastLocationCoordinates,
      origin,
      originCountry,
      originPortExitDate: this._formatDate(originPortExitDate),
      destination,
      destinationCountry,
      eta: this._formatDate(eta),
      updateDate: this._formatDate(updateDate),
      status,
      vins: vins
    });
  }

  static generateEmpty() {
    return new GeolocatedVessel({
      imo: '',
      name: '',
      lastLocationCoordinates: { longitude: 0, latitude: 0 },
      origin: '',
      originCountry: '',
      originPortExitDate: '',
      destination: '',
      destinationCountry: '',
      eta: '',
      updateDate: '',
      status: '',
      vins: []
    });
  }

  private static _formatDate(ISODate) {
    const date = new Date(ISODate);

    const day = this._padTo2Digits(date.getDate());
    const month = this._padTo2Digits(date.getMonth() + 1);
    const year = date.getFullYear();
    const hours = this._padTo2Digits(date.getHours());
    const minutes = this._padTo2Digits(date.getMinutes());

    const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}`;

    return formattedDate;
  }

  private static _padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
  }
}

export default GeolocatedVessel;
