import GetDateFormatedService from 'modules/inbound/shared/domain/GetDateFormatedService';
import { NO_DATA } from 'modules/inbound/shared/domain/NameValues';
import { ShiftItem } from 'modules/inbound/shared/domain/ShiftTypes';
import {
  FactoryBcnItemDTO,
  FactoryBcnTransportDTO
} from '../infrastructure/dto/FactoryBcnDataDTO';
import LocaleService from 'infrastructure/i18n/LocaleService';
import { InboundTranslations } from 'modules/inbound/shared/infraestructure/i18n/typing';
import { InboundText } from 'modules/inbound/shared/infraestructure/i18n/inboundText';
import { StatusPlanType } from 'modules/inbound/shared/domain/StatusPlanTypes';

const factoryBcnTranslations: InboundTranslations = {
  ...InboundText[LocaleService.getLanguage()]
};

export interface FactoryBcnPlanning {
  [key: string]: FactoryBcnDelivery;
}
export interface FactoryBcnDelivery {
  date: string;
  shifts: ShiftItem[];
}

export enum TransportType {
  TRUCK = 'TRUCK',
  TRAIN = 'TRAIN'
}

enum TransportColor {
  TRAIN = 'purple',
  TRUCK = 'white'
}

export interface FactoryBcnTransport {
  code: string;
  type: TransportType;
  providerId: number;
  status: string;
  amount: number;
  arrivalDate: string;
  plateNumber: string;
}

export interface Coverage {
  covered: number;
  security: number;
  critical: number;
}

class FactoryBcn {
  code: string;
  models: string;
  description: string;
  supplyContainer: string;
  supplyContainerCapacity: string;
  warehouseMRT: string;
  stock: string;
  lockedStock: string;
  lines: string;
  coverage: Coverage;
  planning: FactoryBcnPlanning;
  margin: string;
  stockCoverageMRT: string;
  readyStock: string;
  lockedStockBCN: string;
  locations: string;
  warehouseBCN: string;
  stockInReview: string;
  inTime: string;
  inPieces: string;
  inContainer: string;
  lineMovementType: string;
  lineMovementAmount: string;
  lineMovementDate: string;
  stockT2: number | string;
  stockT2UpdatedAt: string;

  constructor({
    code,
    models,
    description,
    supplyContainer,
    supplyContainerCapacity,
    warehouseMRT,
    stock,
    lockedStock,
    lines,
    coverage,
    planning,
    margin,
    stockCoverageMRT,
    readyStock,
    lockedStockBCN,
    locations,
    warehouseBCN,
    stockInReview,
    inTime,
    inPieces,
    inContainer,
    lineMovementType,
    lineMovementAmount,
    lineMovementDate,
    stockT2,
    stockT2UpdatedAt
  }: FactoryBcn) {
    this.code = code;
    this.models = models;
    this.description = description;
    this.supplyContainer = supplyContainer;
    this.warehouseMRT = warehouseMRT;
    this.supplyContainerCapacity = supplyContainerCapacity;
    this.stock = stock;
    this.lockedStock = lockedStock;
    this.lines = lines;
    this.coverage = coverage;
    this.planning = planning;
    this.margin = margin;
    this.stockCoverageMRT = stockCoverageMRT;
    this.readyStock = readyStock;
    this.lockedStockBCN = lockedStockBCN;
    this.locations = locations;
    this.warehouseBCN = warehouseBCN;
    this.stockInReview = stockInReview;
    this.inTime = inTime;
    this.inPieces = inPieces;
    this.inContainer = inContainer;
    this.lineMovementType = lineMovementType;
    this.lineMovementAmount = lineMovementAmount;
    this.lineMovementDate = lineMovementDate;
    this.stockT2 = stockT2;
    this.stockT2UpdatedAt = stockT2UpdatedAt;
  }

  private static _formatThousandsDot(quantity) {
    if (quantity === null) {
      return NO_DATA;
    }
    return quantity.toLocaleString('de-DE');
  }

  private static _formattedTransportStatus(transport) {
    switch (transport) {
      case StatusPlanType.TransportPending:
        return `${factoryBcnTranslations.inConsolidation} ${factoryBcnTranslations.tp}`;
      case StatusPlanType.TransportInTransit:
        return `${factoryBcnTranslations.inExpedition} ${factoryBcnTranslations.tr}`;
      case StatusPlanType.TransportInPlant:
        return `${factoryBcnTranslations.unloadingInPlant} ${factoryBcnTranslations.ep}`;
      default:
        return NO_DATA;
    }
  }

  private static _formatShifts(
    code: string,
    transit: FactoryBcnTransportDTO
  ): FactoryBcnTransport {
    return {
      code: code,
      type: TransportType[transit.transport_type],
      providerId: transit.provider_id,
      status: this._formattedTransportStatus(transit.type),
      amount: transit.amount,
      arrivalDate: GetDateFormatedService.getFullDateTime(
        transit.delivery_time
      ),
      plateNumber: transit.plate_number
    };
  }

  private static _generateFormatPlanning(code, planning): FactoryBcnPlanning {
    let formatPlanning: FactoryBcnPlanning = {};

    planning.forEach((planningItem) => {
      formatPlanning[planningItem.date] = {
        date: planningItem.date,
        shifts: planningItem.shifts.map((shift) => {
          return {
            shift: shift.shift,
            amount:
              shift.amount === shift.remaining_amount
                ? `${shift.amount}`
                : `${shift.amount} | ${
                    shift.remaining_amount >= 0 &&
                    Math.round(shift.remaining_amount)
                  }`,
            stickerAmount:
              shift.transits.length === 1
                ? `${shift.transits[0]?.amount}`
                : null,
            color: TransportColor[shift.transits[0]?.transport_type],
            modalData: shift.transits.map((transit) => {
              return this._formatShifts(code, transit);
            })
          };
        })
      };
    });

    return formatPlanning;
  }

  private static _formatLineMovementAmount(movement) {
    let formatLineMovementAmount: string[] = [];
    if (movement.amount.length) {
      movement.amount.map((item) =>
        formatLineMovementAmount.push(this._formatThousandsDot(item))
      );
    }
    if (formatLineMovementAmount.length) {
      return formatLineMovementAmount.join(' | ');
    }
    return NO_DATA;
  }

  private static _formatMRTMarginAndCoverage(item) {
    if (item === 'MAX') {
      return item;
    }
    return GetDateFormatedService.formatSecondsToHours(item, 1);
  }

  private static _formatMovementDates(date) {
    if (date) {
      return GetDateFormatedService.getShortDateTime(date);
    }
    return NO_DATA;
  }

  static generateFromDTO(stockItem: FactoryBcnItemDTO): FactoryBcn {
    return new FactoryBcn({
      code: stockItem.code,
      models: stockItem.models.join(' | ') || NO_DATA,
      description: stockItem.description || NO_DATA,
      supplyContainer: stockItem.supply_container,
      supplyContainerCapacity: this._formatThousandsDot(
        stockItem.supply_container_capacity
      ),
      warehouseMRT: stockItem.MRT.warehouse || NO_DATA,
      stock: this._formatThousandsDot(stockItem.MRT.stock) || 0,
      lockedStock: this._formatThousandsDot(stockItem.MRT.locked_stock) || 0,
      lines: stockItem.lines.join(' | ').replace(/\d/g, 'ML$&'),
      coverage: stockItem.coverage,
      margin: this._formatMRTMarginAndCoverage(stockItem.MRT.margin),
      planning: this._generateFormatPlanning(
        stockItem.code,
        stockItem.planning
      ),
      stockCoverageMRT: this._formatMRTMarginAndCoverage(
        stockItem.MRT.stock_coverage
      ),
      readyStock: this._formatThousandsDot(stockItem.BCN.stock_listo) || 0,
      lockedStockBCN: this._formatThousandsDot(stockItem.BCN.stock_bloqueado),
      locations: stockItem.BCN?.locations.length
        ? stockItem.BCN.locations
            .map((item) => `(${item.stock})${item.location}`)
            .join(' | ')
        : NO_DATA,
      warehouseBCN: stockItem.BCN.warehouse || NO_DATA,
      stockInReview:
        this._formatThousandsDot(stockItem.BCN.stock_in_review) || 0,
      inTime:
        GetDateFormatedService.formatSecondsToHours(
          stockItem.material_to_deliver.in_time,
          1
        ) || NO_DATA,
      inPieces:
        this._formatThousandsDot(stockItem.material_to_deliver.in_pieces) || 0,
      inContainer:
        this._formatThousandsDot(stockItem.material_to_deliver.in_containers) ||
        0,
      lineMovementType: stockItem.last_line_movement?.type?.length
        ? stockItem.last_line_movement?.type.join(' | ')
        : NO_DATA,
      lineMovementAmount: this._formatLineMovementAmount(
        stockItem.last_line_movement
      ),
      lineMovementDate: this._formatMovementDates(
        stockItem.last_line_movement?.date
      ),
      stockT2: stockItem.gonvauto_tier2?.stock
        ? this._formatThousandsDot(stockItem.gonvauto_tier2.stock)
        : NO_DATA,
      stockT2UpdatedAt: this._formatMovementDates(
        stockItem.gonvauto_tier2?.updated_at
      )
    });
  }
}

export default FactoryBcn;
