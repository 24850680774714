import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import GlobalAppStyles, { AppWrapper } from './AppStyles';
import { Amplify } from 'aws-amplify';
import { useDispatch, useSelector } from 'react-redux';
import { awsConfig } from './aws-exports';
import routingService from 'infrastructure/routes/RoutingService';
import { RouteItemProps } from 'infrastructure/routes/types';
import { UserGroup, UserRole } from 'modules/shared/domain/UserRole';
import {
  initLogger,
  setUserRoleToLogger,
  setUserToLogger
} from 'infrastructure/logger';
import {
  getUserInbound,
  postLoginWeb
} from 'modules/inbound/shared/ui/redux/UserInboundReducer/async';
import { getUserInboundInfo } from 'modules/inbound/shared/ui/redux/UserInboundReducer';
import ExtensibleSidebar from 'modules/shared/layout/containers/ExtensibleSidebar';
import { lazyRetry } from 'modules/shared/hooks/lazyRetry';
import {
  getCheckUserRequestStatusFulfilled,
  getIsUserLoggedIn,
  getLogedUserGroup,
  getUsername
} from 'modules/shared/infrastructure/redux/UserReducer';
import {
  checkUser,
  getUserGroup
} from 'modules/shared/infrastructure/redux/UserReducer/async';
import { LoginPage } from 'modules/shared/layout/pages/LoginPage/LoginPage';
import Toastify from 'modules/shared/layout/elements/Toastify';

Amplify.configure(awsConfig);

const HomePage = lazy(() =>
  lazyRetry(() => import('modules/shared/layout/pages/HomePage/HomePage'))
);

const App: React.FunctionComponent = () => {
  const dispatch = useDispatch();

  const [landingMenuItems, setLandingMenuItems] = useState<RouteItemProps[]>(
    []
  );
  const [outboundMenuItems, setOutboundMenuItems] = useState<RouteItemProps[]>(
    []
  );
  const [inboundMenuItems, setInboundMenuItems] = useState<RouteItemProps[]>(
    []
  );
  const [inboundManagementMenuItems, setInboundManagementMenuItems] = useState<
    RouteItemProps[]
  >([]);
  const [inboundRoutes, setInboundRoutes] = useState<RouteItemProps[]>([]);

  const [loading, setLoading] = useState(true);

  const userInfo = useSelector(getUserInboundInfo);
  const userGroup = useSelector(getLogedUserGroup);
  const username = useSelector(getUsername);
  const isUserLogged = useSelector(getIsUserLoggedIn);
  const isCheckUserFulfilled = useSelector(getCheckUserRequestStatusFulfilled);

  useEffect(() => {
    isUserLogged && initLogger();
  }, [isUserLogged]);

  useEffect(() => {
    isUserLogged && dispatch(getUserGroup());
    if (!username) {
      dispatch(checkUser());
    } else {
      setLoading(false);
    }
  }, [isUserLogged, username, dispatch]);

  useEffect(() => {
    setLoading(!isCheckUserFulfilled);
  }, [isCheckUserFulfilled]);

  useEffect(() => {
    if (userGroup.includes(UserGroup.INBOUND)) {
      dispatch(postLoginWeb());
      dispatch(getUserInbound());
    }
  }, [dispatch, userGroup]);

  useEffect(() => {
    async function asyncFunction() {
      const landingRoutes = routingService.getLandingRoutes(userGroup);
      landingRoutes && setLandingMenuItems(landingRoutes);

      if (userGroup.includes(UserGroup.OUTBOUND)) {
        const outboundRoutes = routingService.getOutboundRoutes();
        setOutboundMenuItems(outboundRoutes);
      } else {
        setOutboundMenuItems([]);
      }
      // TO-DO: Remove role as a parameter once panel 17 appears in user information from BE
      if (userGroup.includes(UserGroup.INBOUND)) {
        const inboundRoutes = routingService.getInboundRoutes(
          userInfo.dashboards,
          userInfo.role
        );
        // TO-DO: Remove role as a parameter once panel 17 appears in user information from BE
        const inboundSidebarRoutes: RouteItemProps[] =
          routingService.getInboundSidebarRoutes(
            userInfo.dashboards,
            userInfo.role
          );
        const inboundManagementSidebarRoutes: RouteItemProps[] =
          routingService.getInboundManagementSidebarRoutes(userInfo.dashboards);

        setInboundRoutes(inboundRoutes);
        setInboundMenuItems(inboundSidebarRoutes);
        setInboundManagementMenuItems(inboundManagementSidebarRoutes);
      } else {
        setInboundRoutes([]);
        setInboundMenuItems([]);
        setInboundManagementMenuItems([]);
      }
    }
    asyncFunction();
  }, [userGroup, userInfo.dashboards, userInfo.role]);

  useEffect(() => {
    if (
      userGroup.includes(UserGroup.INBOUND) &&
      userGroup.includes(UserGroup.OUTBOUND)
    ) {
      if (userInfo.role !== UserRole.NONE) {
        setUserRoleToLogger(`${userInfo.role}-${UserRole.OUTBOUND}`);
      }
      return;
    }
    if (userGroup.includes(UserGroup.OUTBOUND)) {
      setUserRoleToLogger(UserRole.OUTBOUND);
      return;
    }
    if (
      userGroup.includes(UserGroup.INBOUND) &&
      userInfo.role !== UserRole.NONE
    ) {
      setUserRoleToLogger(userInfo.role);
    }
  }, [userGroup, userInfo.role]);

  useEffect(() => {
    if (username) {
      setUserToLogger(username);
    }
  }, [username]);

  const getRouteComponent = (path, Component) => (
    <Route key={path} path={path} element={<Component />} />
  );

  const getSubRoutes = (item) => {
    return (
      item.subItems?.length &&
      item.subItems.map((subItem) => {
        if (subItem.path && subItem.element) {
          return getRouteComponent(subItem.path, subItem.element);
        }
        return subItem.subItems?.map((subSubItem) => {
          if (subSubItem.path && subSubItem.element) {
            return getRouteComponent(subSubItem.path, subSubItem.element);
          }
        });
      })
    );
  };

  const getLandingRoutes = () =>
    landingMenuItems.length &&
    landingMenuItems.map(
      (item) =>
        item.subItems?.length &&
        item.subItems.map((subItem) => {
          if (subItem.path && subItem.element) {
            return getRouteComponent(subItem.path, subItem.element);
          }
          return getSubRoutes(item);
        })
    );

  const getOutboundRoutes = () =>
    outboundMenuItems.length &&
    outboundMenuItems.map(
      (item) =>
        item.subItems?.length &&
        item.subItems.map((subItem) => {
          if (subItem.path && subItem.element) {
            return getRouteComponent(subItem.path, subItem.element);
          }
          return getSubRoutes(item);
        })
    );

  const getInboundRoutes = () =>
    inboundRoutes.length &&
    inboundRoutes.map((item) => {
      if (item.path && item.element) {
        return getRouteComponent(item.path, item.element);
      }
      return getSubRoutes(item);
    });

  return (
    <div>
      <GlobalAppStyles />
      <Toastify />
      {!isUserLogged && !loading && <LoginPage />}
      {isUserLogged && (
        <AppWrapper>
          <ExtensibleSidebar
            items={[
              ...landingMenuItems,
              ...inboundMenuItems,
              ...outboundMenuItems,
              ...inboundManagementMenuItems
            ]}
            outboundUserRole={userGroup.includes(UserGroup.OUTBOUND)}
          />
          <Suspense fallback={<div data-cy="suspense" />}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              {userGroup.includes(UserGroup.OUTBOUND) && getOutboundRoutes()}
              {userGroup.includes(UserGroup.INBOUND) && getInboundRoutes()}
              {getLandingRoutes()}
            </Routes>
          </Suspense>
        </AppWrapper>
      )}
    </div>
  );
};
export default App;
