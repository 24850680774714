const INBOUND_BASE_URL = process.env.REACT_APP_INBOUND_API_BASE_URL;
const USER_PROVIDERS = '/users/providers';
const USER_DASHBOARDS = '/master/master-dashboards';
const API_USERS = `${INBOUND_BASE_URL}/master/users`;
const API_GET_DOWNLOAD_USERS = `${API_USERS}/download`;
const API_RESET_PASSWORD = `${INBOUND_BASE_URL}/master/users/reset`;
const API_GET_USER_PROVIDERS = `${INBOUND_BASE_URL}${USER_PROVIDERS}`;
const API_GET_USER_DASHBOARDS = `${INBOUND_BASE_URL}${USER_DASHBOARDS}`;
export {
  API_USERS,
  API_GET_USER_PROVIDERS,
  API_RESET_PASSWORD,
  API_GET_USER_DASHBOARDS,
  API_GET_DOWNLOAD_USERS
};
