import { createSlice } from '@reduxjs/toolkit';
import { ContactListProps } from './types';
import { deleteContact, getProviderContactList } from './async';

export const initialState: ContactListProps = {
  currentSelectedProviderId: 0,
  providerIdsCurrentPage: [],
  contactList: [],
  requestStatusContactList: '',
  providersWithContactList: [],
  isOpenContactListModal: false,
  isVisibleManageContactButton: false,
  deleteProviderContactRequestStatus: '',
  deleteProviderContactResponse: { statusCode: 0, message: '' },
  isOpenDeleteModal: false,
  selectedContact: { id: '', name: '', role: '', telephone: '', email: '' }
};

const contactListSlice = createSlice({
  name: 'contactList',
  initialState,
  reducers: {
    updateCurrentSelectedProviderId: (state, action) => {
      state.currentSelectedProviderId = action.payload;
    },
    updateProviderIdsCurrentPage: (state, action) => {
      state.providerIdsCurrentPage = action.payload;
    },
    updateIsContactListModalOpen: (state, action) => {
      state.isOpenContactListModal = action.payload;
    },
    updateIsVisibleManageContactButton: (state, action) => {
      state.isVisibleManageContactButton = action.payload;
    },
    updateIsOpenDeleteModal: (state, action) => {
      state.isOpenDeleteModal = action.payload;
    },
    updateSelectedContact: (state, action) => {
      state.selectedContact = action.payload.id
        ? action.payload
        : initialState.selectedContact;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getProviderContactList.fulfilled, (state, action) => {
      state.contactList = action.payload.contactList;
      state.requestStatusContactList = action.meta.requestStatus;
      state.providersWithContactList = action.payload.contactList
        .filter((item) => !!item.contacts.length)
        .map((item) => item.id);
    });
    builder.addCase(getProviderContactList.pending, (state, action) => {
      state.requestStatusContactList = action.meta.requestStatus;
    });
    builder.addCase(getProviderContactList.rejected, (state, action) => {
      state.requestStatusContactList = action.meta.requestStatus;
    });
    builder.addCase(deleteContact.fulfilled, (state, action) => {
      state.deleteProviderContactResponse = action.payload;
      state.deleteProviderContactRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(deleteContact.pending, (state, action) => {
      state.deleteProviderContactRequestStatus = action.meta.requestStatus;
    });
    builder.addCase(deleteContact.rejected, (state, action) => {
      state.deleteProviderContactRequestStatus = action.meta.requestStatus;
    });
  }
});

export const getCurrentSelectedProviderId = (state) =>
  state.contactListState.currentSelectedProviderId;
export const getProviderIdsCurrentPage = (state) =>
  state.contactListState.providerIdsCurrentPage;
export const getContactListById = (state) => {
  return state.contactListState.contactList.filter(
    (item) => item.id === state.contactListState.currentSelectedProviderId
  )[0];
};
export const getProvidersWithContacts = (state) =>
  state.contactListState.providersWithContactList;
export const getIsOpenContactListModal = (state) =>
  state.contactListState.isOpenContactListModal;
export const getIsVisibleManageContactButton = (state) =>
  state.contactListState.isVisibleManageContactButton;
export const getContactListIsPending = (state) =>
  state.contactListState.requestStatusContactList === 'pending';
export const getContactListIsRejected = (state) =>
  state.contactListState.requestStatusContactList === 'rejected';
export const getIsDeletedContactFulfilled = (state) =>
  state.contactListState.deleteProviderContactRequestStatus === 'fulfilled' &&
  state.contactListState.deleteProviderContactResponse === 204;
export const getIsDeletedContactRejected = (state) =>
  state.contactListState.deleteProviderContactRequestStatus === 'rejected' ||
  (state.contactListState.deleteProviderContactResponse.statusCode === 400 &&
    state.contactListState.deleteProviderContactResponse.statusCode === 403);
export const getIsOpenDeleteModal = (state) =>
  state.contactListState.isOpenDeleteModal;
export const getSelectedContact = (state) =>
  state.contactListState.selectedContact;

export const {
  updateCurrentSelectedProviderId,
  updateProviderIdsCurrentPage,
  updateIsContactListModalOpen,
  updateIsVisibleManageContactButton,
  updateIsOpenDeleteModal,
  updateSelectedContact
} = contactListSlice.actions;
export default contactListSlice.reducer;
